import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegraPagamento } from 'src/app/shared/models/regra-pagamento.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegraPagamentoService {

  // static URL_BASE_SERVER: string = 'http://127.0.0.1/appPagOnline/api/';
  // static URL_BASE_SERVER: string = 'https://apps-dev.unimedjp.com.br/pagonline/api/';

  constructor(private http : HttpClient) { }

  getRegraPagamento(qtdParcelaAtrasada: number, valor: number) : Observable<RegraPagamento> {
    return this.http.get<RegraPagamento>(environment.serverUrl + environment.contextPath + '/regraPagamento/getRegraPagamento?qtdParcelaAtrasada=' + qtdParcelaAtrasada + '&valorTotal=' + valor);
  }
}
