import {Component, OnInit, ViewChild} from '@angular/core';
import {FaturasService} from 'src/app/core/services/faturas.service';
import {Fatura} from 'src/app/shared/models/fatura.model';
import {SelectionModel} from '@angular/cdk/collections';
import {MatStepper} from '@angular/material/stepper';
import {FormBuilder, Validators} from '@angular/forms';
import {ParcelaPermitida} from 'src/app/shared/models/parcela-permitida.model';
import {RegraPagamentoService} from 'src/app/core/services/regra-pagamento.service';
import {CurrencyPipe} from '@angular/common';
import {DadosPagamento} from 'src/app/shared/models/dados-pagamento.model';
import {ActivatedRoute, Router} from '@angular/router';
import {PagamentoService} from 'src/app/core/services/pagamento.service';

@Component({
  selector: 'app-faturas-em-aberto',
  templateUrl: './faturas-em-aberto.component.html',
  styleUrls: ['./faturas-em-aberto.component.scss']
})
export class FaturasEmAbertoComponent implements OnInit {

  @ViewChild('stepper', {static: false})
  stepper: MatStepper;

  faturasEmAberto: Fatura[];
  hasFaturas = false;

  faturasSelecionadas: SelectionModel<Fatura> = new SelectionModel<Fatura>(true, []);

  columnsToDisplay = ['select', 'vencimento', 'saldo'];

  bandeiraSelecionada = 'Visa';

  meses: any[] = [
    {value: '01', label: '01'},
    {value: '02', label: '02'},
    {value: '03', label: '03'},
    {value: '04', label: '04'},
    {value: '05', label: '05'},
    {value: '06', label: '06'},
    {value: '07', label: '07'},
    {value: '08', label: '08'},
    {value: '09', label: '09'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '12', label: '12'}
  ];

  anos: any[];

  loading = false;
  loadingMessage = 'Carregando<br>faturas...';

  valorTotalParaPagamento = 0;
  valorTotalParaDebitoDesconto = 0;
  valorTotalParaCreditoDesconto = 0;
  taxaDebito = 0;
  taxaCredito = 0;  
  temCampanha = false;

  messageSemFatura: string;

  parcelasPermitidas: ParcelaPermitida[];

  tipoCartaoSelecionado = 'debit';
  parcelaSelecionada: ParcelaPermitida;

  permitirCartaoCredito = false;
  codigoCarteira: string;

  showMessageError = false;
  messageError: string;

  pagamentoForm = this.fb.group({
    tipoCartao: ['debit', [Validators.required]],
    bandeiraCartao: ['Visa', [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    cardHolderName: ['', [Validators.required, Validators.maxLength(30)]],
    cardNumber: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(19)]],
    expirationCardMonth: ['', [Validators.required]],
    expirationCardYear: ['', [Validators.required]],
    securityCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
  });


  constructor(private faturaService: FaturasService,
              private regraPagamentoService: RegraPagamentoService,
              private pagamentoService: PagamentoService,
              private fb: FormBuilder,
              private cp: CurrencyPipe,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.codigoCarteira = this.route.snapshot.paramMap.get('codCarteira');
    this.loading = true;
    this.loadingMessage = 'Carregando<br>faturas...';

    if (this.codigoCarteira !== null && this.codigoCarteira.length === 13) {
      this.faturaService.getFaturasEmAberto(this.codigoCarteira)
        .subscribe(val => {
          this.loading = false;
          this.faturasEmAberto = val;
          this.hasFaturas = val != null && val.length > 0;
          if (!this.hasFaturas) {
            this.messageSemFatura = 'Não há faturas em aberto.';
          }
        }, exception => {
          console.error(exception);
          this.loading = false;
          this.hasFaturas = false;
          this.messageSemFatura = 'Ocorreu um erro na comunicação com o servidor, tente mais tarde.';
        });
    } else {
      this.loading = false;
      this.messageSemFatura = 'Código de Carteira não encontrado.';
    }
    //Listando 10 anos para frente para a validade do cartão.
    let anoAtual = new Date().getFullYear();
    this.anos = [{value: anoAtual, label: anoAtual}]
    for (let i = 1; i <= 10; i++) {
      this.anos.push({value: anoAtual + i, label: anoAtual + i});
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.faturasSelecionadas.selected.length;
    const numRows = this.faturasEmAberto.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.faturasSelecionadas.clear() : this.faturasEmAberto.forEach(row => this.faturasSelecionadas.select(row));
    this.atualizarValorTotalPagamento();
  }

  toggleFaturaSelecionada(row: Fatura) {
    this.faturasSelecionadas.toggle(row);
    if (this.faturasSelecionadas.isSelected(row)) {
      this.faturasEmAberto.forEach(fat => {
        if (row.vencimento > fat.vencimento) {
          this.faturasSelecionadas.select(fat);
        }
      });
    } else {
      this.faturasEmAberto.forEach(fat => {
        if (row.vencimento < fat.vencimento) {
          this.faturasSelecionadas.deselect(fat);
        }
      });
    }
    this.atualizarValorTotalPagamento();
  }

  atualizarValorTotalPagamento() {
    let valorTotal = 0;
    let valorTotalDebitoDesc = 0;
    let valorTotalCreditoDesc = 0;

    this.faturasSelecionadas.selected.forEach(f => {
      valorTotal += f.saldo;
      valorTotalDebitoDesc += f.totalValorDebito;
      valorTotalCreditoDesc += f.totalValorCredito;
      this.temCampanha = f.campanha;
      this.taxaDebito = f.taxaDescontoDebito;
      this.taxaCredito = f.taxaDescontoCredito;
    });
    this.valorTotalParaPagamento = valorTotal;
    this.valorTotalParaDebitoDesconto = valorTotalDebitoDesc;
    this.valorTotalParaCreditoDesconto = valorTotalCreditoDesc;
    
    if (valorTotal === 0) {
      this.stepper.selected.completed = false;
    }
  }

  atualizarParcelasPermitidas() {
    const qtdParcelaAtrasada: number = this.faturasSelecionadas.selected.length;

    this.regraPagamentoService.getRegraPagamento(qtdParcelaAtrasada, this.valorTotalParaPagamento)
      .subscribe(regra => {
        //this.permitirCartaoCredito = regra.allowCredit;
        if (!regra.allowCredit && regra.allowDebit) {
          this.tipoCartaoSelecionado = 'debit';
          this.parcelasPermitidas = [new ParcelaPermitida(1, 'À vista (Débito)')];
          this.parcelaSelecionada = this.parcelasPermitidas[0];
        } else {
          let i = 0;
          this.parcelasPermitidas = [];
          while (i < regra.qtdMaximaParcela) {
            i++;
            const valorFormatado = this.cp.transform(this.valorTotalParaPagamento / i, 'BRL');
         
            if (i === 1) {
              if(!this.temCampanha){
                this.parcelasPermitidas.push(new ParcelaPermitida(i, 'À vista (' + i + 'x de ' + valorFormatado + ')'));
              } else{
                this.parcelasPermitidas.push(new ParcelaPermitida(i, 'À vista (' + i + 'x de ' + this.cp.transform(this.valorTotalParaCreditoDesconto / i, 'BRL')  + ')'));    
              }
              this.parcelaSelecionada = this.parcelasPermitidas[0];
            } else {
              this.parcelasPermitidas.push(new ParcelaPermitida(i, i + 'x de ' + valorFormatado));
            }

           
          }
        }
      }, exception => {
        console.log(exception);
      });
  }

  onSubmitPagamento() {
    this.showMessageError = false;
    const isCreditCard = this.pagamentoForm.value.tipoCartao === 'credit';
    const qtdParcelas: number = isCreditCard ? this.parcelaSelecionada.parcela : 1;
    const dadosPagamento: DadosPagamento = {
      isCredit: isCreditCard,
      numeroParcelas: qtdParcelas,
      email: this.pagamentoForm.value.email,
      cardHolderName: this.pagamentoForm.value.cardHolderName,
      cardNumber: this.pagamentoForm.value.cardNumber,
      codigoCarteira: this.codigoCarteira,
      expirationMonth: this.pagamentoForm.value.expirationCardMonth,
      expirationYear: this.pagamentoForm.value.expirationCardYear,
      securityCode: this.pagamentoForm.value.securityCode,
      userAgent: window.navigator.userAgent,
      faturasSelecionadas: this.faturasSelecionadas.selected,
      brand: this.bandeiraSelecionada
    };
    this.loading = true;
    this.loadingMessage = 'Validando<br>dados...';

    this.pagamentoService.realizarPagamentoCartao(dadosPagamento)
      .subscribe(val => {
          if (this.tipoCartaoSelecionado === 'debit') {
            this.loadingMessage = 'Confirmando<br>transação...';
            window.location.href = val.Payment.AuthenticationUrl;
          } else { // is credit
            this.loading = false;
            this.router.navigate(['/pagamento-realizado', val.id]);
          }
        }, exception => {
          this.loading = false;
          if (exception.error.Payment == null) {
            this.messageError = 'Ocorreu um erro na tentativa do pagamento. Por favor verifique se todas as informações estão corretas. ' +
              'Caso o erro persista tente novamente mais tarde. (' + exception.error.Code + ')';
            if (exception.error.Code === 126) {
              this.messageError = 'Cartão com data de validade expirado.';
            }
            if (exception.error.Code === 185) {
              this.messageError = 'Ocorreu um erro na tentativa do pagamento. Verifique se a bandeira selecionada esta correta.';
            }
          } else {
            this.messageError = exception.error.Payment.ReturnMessage;
          }
          this.showMessageError = true;
          // let returnMessage: string = exception.error.returnMessage;
          // let fieldError: string;
          // if (returnMessage.search(':') !== -1) {
          //   fieldError = returnMessage.split(':')[0];
          //   returnMessage = returnMessage.split(':')[1];
          // } else {
          //   console.log(exception);
          //   returnMessage = 'Ocorreu um erro com a comunicação com a administradora do cartão. Por favor, tente mais tarde.';
          // }
          // if (fieldError !== 'general') {
          //   this.pagamentoForm.get(fieldError).setErrors({incorrect: true});
          // }
          // this.messageError = returnMessage;
          // this.showMessageError = true;
        }
      );
  }

  resetForm() {
    // this.pagamentoForm.reset();
    this.pagamentoForm.value.tipoCartao = 'debit';
    this.tipoCartaoSelecionado = 'debit';
    this.pagamentoForm.controls['bandeiraCartao'].reset();    
  }

  // Step #1
  goToFaturasStep() {
    this.resetForm();
    this.stepper.previous();
    this.stepper.selected.completed = false;
  }

  // Step #2
  goToFormaPagamentoStep() {
    this.stepper.selected.completed = true;
    this.stepper.next();
    this.stepper.selected.completed = false;
    this.atualizarParcelasPermitidas();
  }

}
