import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FaturasEmAbertoComponent } from './pages/faturas-em-aberto/faturas-em-aberto.component';
import {
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import { PagamentoRealizadoComponent } from './pages/pagamento-realizado/pagamento-realizado.component';
import { CreditCardBrandComponent } from './pages/credit-card-brand/credit-card-brand.component';
import { PagamentoErrorComponent } from './pages/pagamento-error/pagamento-error.component';


// export const optionsNgxMask: Partial<IConfig> | (() => Partial<IConfig>);
@NgModule({
  declarations: [FaturasEmAbertoComponent, PagamentoRealizadoComponent, CreditCardBrandComponent, PagamentoErrorComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatCheckboxModule,
        MatStepperModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCardModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        NgxMaskModule.forRoot(null),
        MatTooltipModule
    ],
  providers: [CurrencyPipe],
  exports: [FaturasEmAbertoComponent]
})
export class HomeModule { }
