import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DadosPagamento } from 'src/app/shared/models/dados-pagamento.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComprovantePagamento } from 'src/app/shared/models/comprovante-pagamento.model';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  constructor(private http: HttpClient) { }

  // static URL_BASE_SERVER: string = 'http://127.0.0.1/appPagOnline/api/';
  // static URL_BASE_SERVER: string = 'https://apps-dev.unimedjp.com.br/pagonline/api/';


  realizarPagamentoCartao(dadosPagamento: DadosPagamento): Observable<any> {
    return this.http.post<any>(environment.serverUrl + environment.contextPath + '/pag/realizarPagamento', dadosPagamento);
  }

  getBaixaCartaoById(id: string): Observable<ComprovantePagamento> {
    return this.http.get<ComprovantePagamento>(environment.serverUrl + environment.contextPath   + '/pag/getBaixaCartao?tid='+id);
  }
}
