import {Component, OnInit} from '@angular/core';
import {PagamentoService} from 'src/app/core/services/pagamento.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrencyPipe} from '@angular/common';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
import {ComprovantePagamento} from 'src/app/shared/models/comprovante-pagamento.model';
import {Fatura} from 'src/app/shared/models/fatura.model';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-pagamento-realizado',
  templateUrl: './pagamento-realizado.component.html',
  styleUrls: ['./pagamento-realizado.component.scss']
})
export class PagamentoRealizadoComponent implements OnInit {

  tid: string;

  detalheParcelamento: string;

  comprovantePagamento: ComprovantePagamento;

  faturasPagas: Fatura[];

  columnsToDisplay = ['nossoNumero', 'vencimento', 'saldo'];

  constructor(private pagamentoService: PagamentoService,
              private route: ActivatedRoute,
              private router: Router,
              private cp: CurrencyPipe,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon('pdf-file', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pdf-file.svg'));
  }

  ngOnInit() {
    this.tid = this.route.snapshot.paramMap.get('tid');
    if (this.tid !== null) {
      this.pagamentoService.getBaixaCartaoById(this.tid)
        .subscribe(val => {
          this.comprovantePagamento = val;
          this.faturasPagas = this.comprovantePagamento.faturasPagas;
          if (val.qtdParcela != null) {
            if (val.qtdParcela === 1) {
              this.detalheParcelamento = 'À vista';
            } else {
              const valorFormatado = this.cp.transform(val.valorPago / val.qtdParcela, 'BRL');
              this.detalheParcelamento = val.qtdParcela + 'x de ' + valorFormatado;
            }
          }
        }, exception => {
          console.log(exception);
        });
    }

  }

  navigateToFaturasAbertas() {
    this.router.navigate(['/faturas-em-aberto', this.comprovantePagamento.codCarteira]);
  }

  onClickComprovantePagamentoPDF() {
    window.location.href = environment.comprovanteUrl + '%27' + this.comprovantePagamento.id + '%27';
  }

}
