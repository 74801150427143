import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagamento-error',
  templateUrl: './pagamento-error.component.html',
  styleUrls: ['./pagamento-error.component.scss']
})
export class PagamentoErrorComponent implements OnInit {

  codCarteira: string;

  constructor(private route:ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.codCarteira = this.route.snapshot.paramMap.get('codCarteira');
  }

  navigateToFaturasAbertas() {
    this.router.navigate(['/faturas-em-aberto', this.codCarteira]);
  }

}
