import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Fatura } from 'src/app/shared/models/fatura.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaturasService {
  // static URL_BASE_SERVER: string = 'http://127.0.0.1/appPagOnline/api/';
  // static URL_BASE_SERVER: string = 'https://apps-dev.unimedjp.com.br/pagonline/api/';
  
  
  constructor(private http : HttpClient) { }

  getFaturasEmAberto(codCarteira : string) : Observable<Fatura[]> {
    return this.http.get<Fatura[]>(environment.serverUrl + environment.contextPath + '/faturasEmAberto/getFaturasEmAberto?codCarteira=' + codCarteira);
  }
}
