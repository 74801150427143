import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-credit-card-brand',
  templateUrl: './credit-card-brand.component.html',
  styleUrls: ['./credit-card-brand.component.scss']
})
export class CreditCardBrandComponent implements OnInit {

  visaRegex: RegExp = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  amexRegex: RegExp = new RegExp('^3[47][0-9]{0,13}$');
  masterRegex: RegExp = new RegExp('^5[1-5][0-9]{0,14}|^(222[1-9]|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{0,12}');
  hipercardRegex: RegExp = new RegExp('^(606282\d{0,10}(\d{3})?)|(3841\d{0,15})$');
  maestroRegex: RegExp = new RegExp('^(5018|5020|5038|6036|6304|6759|6761|6763)[0-9]{8,15}$');
  eloRegex: RegExp = new RegExp('^(((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12}$');
  discoverRegex: RegExp = new RegExp('^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9]' +
    '[0-9]|9[01][0-9]|92[0-5])[0-9]{10})$');
  dinersRegex: RegExp = new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{11}$');


  sourceImage = '';

  showBrand = false;

  // tslint:disable-next-line:variable-name
  private _cardNumber: string;

  @Input()
  set cardNumber(cardNumber: string) {
    this._cardNumber = cardNumber;
    if (cardNumber != null && cardNumber.length > 6) {
      this.updateCreditCardBrand();
      this.showBrand = this.sourceImage !== null;
    } else {
      this.showBrand = false;
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  updateCreditCardBrand() {
    if (this.visaRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/visa.svg';
      return;
    }
    if (this.amexRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/amex.svg';
      return;
    }
    if (this.masterRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/master.svg';
      return;
    }
    if (this.hipercardRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/hipercard.svg';
      return;
    }
    if (this.eloRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/elo.svg';
      return;
    }
    if (this.discoverRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/discover.svg';
      return;
    }
    if (this.maestroRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/master.svg';
      return;
    }
    if (this.dinersRegex.test(this._cardNumber)) {
      this.sourceImage = 'assets/images/diners.svg';
      return;
    }
    this.sourceImage = null;
  }

}
