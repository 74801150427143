import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaturasEmAbertoComponent } from './modules/home/pages/faturas-em-aberto/faturas-em-aberto.component';
import { PagamentoRealizadoComponent } from './modules/home/pages/pagamento-realizado/pagamento-realizado.component';
import { PagamentoErrorComponent } from './modules/home/pages/pagamento-error/pagamento-error.component';

const routes: Routes = [
  { path: 'faturas-em-aberto/:codCarteira', component: FaturasEmAbertoComponent },
  { path: 'pagamento-realizado/:tid', component: PagamentoRealizadoComponent },
  { path: 'pagamento-error/:codCarteira', component: PagamentoErrorComponent },
  { path: 'faturas-em-aberto', component: FaturasEmAbertoComponent },
  {path: '', redirectTo: 'faturas-em-aberto', pathMatch: 'full'},
  {path: '**', redirectTo: 'faturas-em-aberto', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
